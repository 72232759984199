// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
import React from 'react';
import { BrowserRouter as Router, Route, Link, Outlet, useParams, Routes } from 'react-router-dom';
import './App.css';

const projects = [
  {
    id: 1,
    title: 'Project 1',
    description: 'Description of Project 1. This is a sample project showcasing my skills in web development using HTML, CSS, and JavaScript.',
    images: ['image1-1.jpg', 'image1-2.jpg', 'image1-3.jpg']
  },
  {
    id: 2,
    title: 'Project 2',
    description: 'Description of Project 2. This project demonstrates my expertise in building full-stack applications using React and Node.js.',
    images: ['image2-1.jpg', 'image2-2.jpg', 'image2-3.jpg']
  },
  {
    id: 3,
    title: 'Project 3',
    description: 'Description of Project 3. In this project, I utilized Python and Django to create a robust web application with a clean and intuitive user interface.',
    images: ['image3-1.jpg', 'image3-2.jpg', 'image3-3.jpg']
  },
  {
    id: 4,
    title: 'Project 4',
    description: 'Description of Project 4. This project highlights my skills in mobile app development using React Native, allowing for cross-platform compatibility.',
    images: ['image4-1.jpg', 'image4-2.jpg', 'image4-3.jpg']
  },
  {
    id: 5,
    title: 'Project 5',
    description: 'Description of Project 5. In this project, I built a machine learning model using TensorFlow to solve a complex problem with a high degree of accuracy.',
    images: ['image5-1.jpg', 'image5-2.jpg', 'image5-3.jpg']
  },
  {
    id: 6,
    title: 'Project 6',
    description: 'Description of Project 6. This project showcases my ability to work with cloud technologies like AWS and Google Cloud Platform to deploy scalable applications.',
    images: ['image6-1.jpg', 'image6-2.jpg', 'image6-3.jpg']
  },
];

function App() {
  return (
    <Router>
      <div className="App">
        <h1>My Portfolio</h1>
        <Routes>
          <Route path="/" element={<ProjectList />} />
          <Route path="/project/:id" element={<ProjectDetail />} />
        </Routes>
      </div>
    </Router>
  );
}

function ProjectList() {
  return (
    <div className="project-list">
      {projects.map(project => (
        <Link key={project.id} to={`/project/${project.id}`} className="project-link">
          <div className="project-item">
            <h3>{project.title}</h3>
          </div>
        </Link>
      ))}
    </div>
  );
}

function ProjectDetail() {
  const { id } = useParams();
  const project = projects.find(p => p.id === parseInt(id));

  if (!project) return <h2>Project not found</h2>;

  return (
    <div className="project-detail">
      <h2>{project.title}</h2>
      <p>{project.description}</p>
      <div className="project-images">
        {project.images.map((image, index) => (
          <img key={index} src={image} alt={`${project.title} ${index + 1}`} />
        ))}
      </div>
    </div>
  );
}

export default App;